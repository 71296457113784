body,
#root {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: 0;
  height: 100%;
  width: 100%;
  color: hsla(270, 17.119554496%, 0%, 0.92);
  font-family: "Futura PT", -apple-system, "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", "Arial", sans-serif;
  background: #f4fff4; //#fafcfb;
}

.App-wrapper {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.Nav {
  margin: 0;
  padding: 0.25rem;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.Nav a {
  padding: 0 0.5rem 0;
}

.App__body {
  max-width: 1080px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
}
